import { BlockConsentInstance } from "./BlockConsent";
import { AsyncHelper, ClaimsGateErrors } from "@claimsgate/core";
import { UserDataService } from "@/helpers/ClaimsGate/DataService";

export async function mounted(state: BlockConsentInstance) {
  try {
    if (!state.userService.getUserId()) {
      return AsyncHelper.onError(ClaimsGateErrors.UserIdMissing);
    }
    state.userDataService = new UserDataService(state.userService.getUserId());

    //await state.userDataService.refresh();

    state.user = state.userDataService.getCache();

    setExistingConsents(state);

    return AsyncHelper.onCompleted(true);
  } catch (exception) {
    console.error(exception);
    return AsyncHelper.onException(exception);
  }
}

/**
 * Checks for existing CGPrivacy consent on the user,
 * and if the users consents array includes a consent for the current workspace
 * will set and disable respective check box
 * @param state
 */
function setExistingConsents(state: BlockConsentInstance) {
  const { workspacePrivacyPolicyConsent, latestTsAndCsConsent } = getExistingConsentsForThisWorkspace(state);
  console.log("Latest consents are", workspacePrivacyPolicyConsent, latestTsAndCsConsent);
  state.existingConsentsForThisWorkspace = { workspacePrivacyPolicyConsent, latestTsAndCsConsent };

  state.checkboxes.workspacePrivacy.answer = workspacePrivacyPolicyConsent?.status ?? "";
  state.checkboxes.workspacePrivacy.disabled = workspacePrivacyPolicyConsent?.status === "accepted";

  // ! If we have a Workspace Privacy Policy, where is this
  const claimsgatePrivacyPolicyConsent = state.user.privacyConsent;

  console.log("<!> Claimsgate Privacy Policy Consent is", claimsgatePrivacyPolicyConsent);
  console.log("<!> Workspace Privacy Policy Consent is", workspacePrivacyPolicyConsent);

  if (claimsgatePrivacyPolicyConsent === "accepted") {
    state.checkboxes.cgPrivacy.answer = state.user.privacyConsent;
    state.checkboxes.cgPrivacy.disabled = true;
    state.checkboxes.sms.answer = "accepted";
    state.checkboxes.sms.disabled = true;

    // If there is no Workspace Privacy Policy Link, we can pre-load th Claims Gate Privacy Policy
    if (!state.workspacePrivacyPolicyLink) {
      state.allConsents.answer = "accepted";
      state.allConsents.disabled = true;
      return;
    }
  }

  // If there is a Workspace Privacy Policy, we need a combination of the two consents to be accepted
  if (state.workspacePrivacyPolicyLink) {
    if (workspacePrivacyPolicyConsent?.status === "accepted" && state.user.privacyConsent === "accepted") {
      state.allConsents.answer = "accepted";
      state.allConsents.disabled = true;
    }
  }
}

function getExistingConsentsForThisWorkspace(state: BlockConsentInstance) {
  const userConsents = state.user.consents ?? [];
  console.log("All consents are", userConsents);

  const existingConsents = userConsents.filter((consent) => consent.workspaceId === state.navigationWorkspaceId);
  console.log("Existing consents are", existingConsents);

  const workspacePrivacyPolicyConsent = existingConsents
    .filter((consent) => consent.type === "PrivacyPolicy")
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    .shift();

  const latestTsAndCsConsent = existingConsents
    .filter((consent) => consent.type === "TermsAndConditions")
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    .shift();

  console.log("Existing latest consents are", workspacePrivacyPolicyConsent, latestTsAndCsConsent);

  return { workspacePrivacyPolicyConsent, latestTsAndCsConsent };
}
