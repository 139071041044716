import Vue from "vue";
import { methods } from "./methods";
import { getProps } from "./props";
import { identificationFlow } from "@claimsgate/core";
import { getData } from "./data";
import { computed, mappedComputed } from "./computed";

export { methods, getProps, getData, computed, mappedComputed };

// Defined this here as wanted to use local type
export interface BlockKycProps {
  padding: string;
  allowOtherTitle: boolean;
  title: boolean;
  middleName: boolean;
  collectDateOfBirth: boolean;
  isBuilder: boolean;
  faceMatch: boolean;
  identificationFlow: identificationFlow;
  requireAgeCheck: boolean;
  allowInternationalClients: boolean;
  allowManualUploadFallback: boolean;
  minAge: string;
  maxAge: string;
}

export type Methods = keyof typeof methods;
export type Data = ReturnType<typeof getData>;

export type Computed = { [K in keyof typeof computed]: ReturnType<(typeof computed)[K]> } & ReturnType<
  typeof mappedComputed
>;

export type BlockKycInstance = Vue & {
  $refs: { blockDateOfBirth?: { validations: () => boolean } };
} & Data &
  Computed &
  BlockKycProps;
