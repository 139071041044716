<script lang="ts">
// Types
// eslint-disable-next-line no-unused-vars
import { Props, UserBlockProps } from "@/types";

import BlockDatePicker from "@/components/shared/blocks/datepicker.vue";
//import BlockPadding from "@/components/shared/blocks/padding.vue";

// Services
import { InfoModalService } from "@/components/shared/global/informationModal/InfoModalService";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { UserVariableService } from "@/helpers/ClaimsGate/UserVariableService";
import { eventComputed } from "@/state/helpers";
import { msg } from "@/helpers/ClaimsGate/ResponseMessageService";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
import { UserDataService } from "@/helpers/ClaimsGate/DataService";
import Vue from "vue";
let inputPropsValue = inputProps({ answerType: "date", extraProps: ["inputType"] }) as Props.BlockInput;
delete inputPropsValue.label;
delete inputPropsValue.info;

export default Vue.extend({
  name: "DateOfBirth",
  components: {
    BlockDatePicker,
    //BlockPadding,
  },
  props: {
    minAge: {
      type: String,
      description: "If the requireAgeCheck is true, this is the minimum age required of the user",
      default: "18",
    } as Props.CGPropExtras<string>,
    maxAge: {
      type: String,
      description: "If the requireAgeCheck is true, this is the maximum age required of the user",
      default: "100",
    } as Props.CGPropExtras<string>,
    requireAgeCheck: {
      type: Boolean,
      description: "If true, the user must be between the min and max age",
      default: false,
    } as Props.CGPropExtras<boolean>,
    userIsVerified: {
      type: Boolean,
      default: false,
    },
    ...inputPropsValue,
  },
  data() {
    return {
      unparsedAnswer: "",
      userDataService: undefined as UserDataService,
      userService: getUserHelper(),
      blockDateOfBirth: UserVariableService.getBlocks(["dateOfBirth"]).dateOfBirth as UserBlockProps.DateOfBirth,
      infoModalService: new InfoModalService(this.$infoModal),
      user: {
        dateOfBirth: undefined,
      },
      uiMessages: {
        mustBe18: "You must be over 18 years old to continue",
        dateOfBirthMissing: "Please enter your date of birth",
      },
      //disabled: false,
    };
  },
  computed: {
    ...eventComputed,

    yearsEnd: function () {
      return new Date().getFullYear();
    },
  },

  methods: {
    emitUnparsedDateOfBirth(unparsedAnswer: string) {
      this.$emit("update:unparsedAnswer", unparsedAnswer);
    },
    validations(): boolean {
      this.blockDateOfBirth.state = null;
      this.blockDateOfBirth.invalidFeedback = "";

      if (!this.user.dateOfBirth) {
        return false;
      }

      if (this.requireAgeCheck && this.user.dateOfBirth) {
        const isOverMinAge = this.validateAge(new Date(this.user.dateOfBirth), parseInt(this.minAge));
        const isOverMaxAge = this.validateAge(new Date(this.user.dateOfBirth), parseInt(this.maxAge));

        if (!isOverMinAge) {
          this.blockDateOfBirth.state = false;
          this.blockDateOfBirth.invalidFeedback = `You must be ${this.minAge} years or older to continue`;
          return false;
        }

        if (isOverMaxAge) {
          this.blockDateOfBirth.state = false;
          this.blockDateOfBirth.invalidFeedback = `You must be ${this.maxAge} years or younger to continue`;
          return false;
        }
      }
      this.blockDateOfBirth.state = true;
      return true;
    },
    computes(): boolean {
      return true;
    },
    /** Valiates if a given date is older than a give number of years */
    validateAge(dateOfBirth: Date, minimumAge: number): boolean {
      const currentDate = new Date();
      const minimumDateOfBirth = new Date(
        currentDate.getFullYear() - minimumAge,
        currentDate.getMonth(),
        currentDate.getDate()
      );
      return minimumDateOfBirth.getTime() > dateOfBirth.getTime();
    },
  },
  async mounted() {
    try {
      this.user.dateOfBirth = this.answer;

      // If the block requires the user to be over the age of 18 but they have entered a date of birth
      // which makes them under the age of 18, then we need to enable the block so they can change it.

      if (this.requireAgeCheck && this.user.dateOfBirth) {
        const isOverMinAge = this.validateAge(new Date(this.user.dateOfBirth), parseInt(this.minAge));
        const isOverMaxAge = this.validateAge(new Date(this.user.dateOfBirth), parseInt(this.maxAge));

        // If the user is above the minimum age but below the maximum age, then we need to disable the block.
        if (isOverMinAge && !isOverMaxAge && this.userIsVerified) {
          this.blockDateOfBirth.disabled = true;
        }
      }
    } catch (exception) {
      console.error(exception);
      await this.infoModalService.fire("error", { text: msg.errors.pageLoadFailed });
    }
  },
  watch: {
    user: {
      handler() {
        if (this.user.dateOfBirth && this.user.dateOfBirth.length > 0) {
          this.$emit("update:answer", this.user.dateOfBirth);
        }
      },
      deep: true,
    },
    padding: {
      immediate: true,
      handler() {
        this.blockDateOfBirth.padding = this.padding;
      },
    },
    invalidFeedback: {
      immediate: true,
      handler() {
        this.blockDateOfBirth.invalidFeedback = this.invalidFeedback;
      },
    },
    state: {
      handler() {
        this.blockDateOfBirth.state = this.state;
      },
      immediate: true,
    },
    disabled: {
      handler() {
        console.log("disabled handler block dob", this.disabled);
        this.blockDateOfBirth.disabled = this.disabled;
      },
      immediate: true,
    },
  },
});
</script>
<template>
  <div>
    <BlockDatePicker
      v-bind="blockDateOfBirth"
      :answer.sync="user.dateOfBirth"
      @update:unparsedAnswer="emitUnparsedDateOfBirth"
      :yearsEnd="yearsEnd"
    />
  </div>
</template>
