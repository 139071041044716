import Vue from "vue";
import { methods } from "./methods";
import { getProps } from "./props";
import { getData } from "./data";
import { computed, mappedComputed } from "./computed";
import RecoverAccount from "@/components/shared/auth/RecoverAccount.vue";
import { BlockInstanceProps, Props } from "@/types";
export { methods, getProps, getData, computed, mappedComputed };

export type Methods = keyof typeof methods;
export type Data = ReturnType<typeof getData>;

export type Computed = { [K in keyof typeof computed]: ReturnType<typeof computed[K]> } & ReturnType<
  typeof mappedComputed
>;

export interface BlockEmailRefs {
  $refs: {
    recoverAccount: typeof RecoverAccount;
  };
}

/**
 * Passing Computed as a generic prevents TS from throwing circular reference error when
 * computed functions use a state object with `BlockEmailInstance` as its type
 */
export type BlockEmailInstance = Vue &
  Data &
  Methods &
  Computed &
  BlockInstanceProps<Props.BlockEmail> &
  BlockEmailRefs;
