import { UserVariableService } from "@/helpers/ClaimsGate/UserVariableService";
import { reject } from "@/helpers/vue";

import { BlockEmailInstance } from "../BlockEmail";
import { checkForExistingUser } from "./checkForExistingUser";
import { fireEmailValidationFailedEvent, fireEmailValidationPassedEvent } from "./fireEmailSentryEvents";
import { saveEmail } from "./saveEmail";
import { validateWithMailgun } from "./validateWithMailgun";

export async function computes(state: BlockEmailInstance) {
  if (blockIsComplete(state)) return true;

  resetRecoverModals(state);

  const submitPromises = await Promise.all([
    validateWithMailgun(state),
    UserVariableService.checkIfUserExists(state.providedEmail, undefined),
  ]);

  const { data: isValidEmail, exception: mailgunException } = submitPromises[0];
  if (mailgunException) {
    throw new Error(mailgunException.getMessage());
  }

  if (isValidEmail) {
    fireEmailValidationPassedEvent(state);
  } else {
    fireEmailValidationFailedEvent(state);
    return false;
  }
  const { data: userExistsResult, exception: userExistsException } = submitPromises[1];

  if (userExistsException || !userExistsResult) {
    throw new Error(userExistsException ?? userExistsResult);
  }

  if (!checkForExistingUser(state, userExistsResult)) return false;

  if (state.enforceVerification) {
    state.recover.verifyUserIdentifier = { email: state.providedEmail };
    return false;
  }

  return saveEmail(state);
}

export interface UserExistsResult {
  status: boolean;
  user?: { phoneNumber: boolean; email: boolean };
}

export function askUserToLogin(state: BlockEmailInstance, result: UserExistsResult) {
  window.console.log("[result]", result);
  if (result.user.email) {
    state.BlockInputs.email.state = false;
    state.BlockInputs.email.invalidFeedback = state.uiMessages.emailAssociatedOtherAccount;
    reject(state, state.uiMessages.emailAssociatedOtherAccount);
  }

  // trigger loading of the recover modal
  state.recover.userIdentifier = { email: state.providedEmail };
}

/**
 * * This statement assumes that if firebaseAuth and firestore have email are set with the same email
 * * and `user.isVerifiedEmail` we can skip all as the block is complete
 */
export function blockIsComplete(state: BlockEmailInstance) {
  const emailDefinedAndNoVerificationRequired = state.uiToggles.isEmailDefined && !state.enforceVerification;

  const emailDefinedAndVerified =
    state.uiToggles.isEmailDefined && state.enforceVerification && state?.user?.isVerifiedEmail;

  if (emailDefinedAndNoVerificationRequired || emailDefinedAndVerified) {
    return true;
  }

  return false;
}

/**
 * In the possibliity that a recover modal was opened,
 * but then closed by the user/or fails it self,
 * the modal won't pop a second time if the user retries with the same email unless the `state.recover` object is reset
 * @param state
 */
function resetRecoverModals(state: BlockEmailInstance) {
  state.recover.userIdentifier = { email: undefined };
  state.recover.verifyUserIdentifier = { email: undefined };
}
