import { getFirebaseBackend } from "@/authUtils";
import { BlockEmailInstance } from "../BlockEmail";
import { UserExistsResult, askUserToLogin } from "./computes";

/**
 * returns false and calls `askUserToLogin if the providedEmail exists in auth, but the user is not logged in,
 * @param state
 * @param userExistsResult
 * @returns
 */
export function checkForExistingUser(state: BlockEmailInstance, userExistsResult: UserExistsResult) {
  // If the providedEmail exists in auth, but the user is not logged in, they need to recover
  if (userExistsResult.user.email && !state.uiToggles.isEmailDefined) {
    if (getFirebaseBackend().firebaseAuth().currentUser.email !== state.providedEmail) {
      state.recoverAccountIsProcessing = true;
      askUserToLogin(state, userExistsResult);

      return false;
    }
  }

  return true;
}
