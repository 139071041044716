<script lang="ts">
import Vue from "vue";
import { getProps, getData, mappedComputed, BlockConsentInstance } from "./BlockConsent";
import { methods } from "./methods";

import BlockCheckbox from "@/components/shared/blocks/checkbox.vue";
import { computed } from "./computed";
export default Vue.extend({
  name: "BlockConsent",
  components: {
    //BlockParagraph,
    BlockCheckbox,
  },
  data() {
    return { ...getData(this) };
  },
  props: { ...getProps() },
  computed: {
    ...mappedComputed(),
    cgPrivacyPolicyLinkText(): string {
      return computed.cgPrivacyPolicyLinkText(this as any as BlockConsentInstance);
    },
    workspaceTermsAndConditionsLinkParsed(): string {
      return computed.workspaceTermsAndConditionsLinkParsed(this as any as BlockConsentInstance);
    },

    workspaceId(): string {
      return this.$store.getters["navigation/getWorkspaceId"];
    },

    workspaceAllowCustomPolicy(): boolean {
      return this.$store.getters["navigation/getWorkspaceAllowCustomPolicy"];
    },

    checkConsents(): boolean {
      return methods.checkConsents(this as any as BlockConsentInstance);
    },
  },
  async mounted() {
    await methods.mounted(this as any as BlockConsentInstance);
  },
  methods: {
    async validations() {
      return methods.validations(this as any as BlockConsentInstance);
    },
    async computes() {
      return methods.computes(this as any as BlockConsentInstance);
    },
  },
  watch: {
    workspacePrivacyPolicyLink() {
      // Just in case the user adds a policy link, need to make sure the render updates with correct wording
      if (this.workspacePrivacyPolicyLink.length > 0) {
        this.checkboxes.cgPrivacy.linkText = "Claims Gate Privacy Policy";
      } else {
        this.checkboxes.cgPrivacy.linkText = "Privacy Policy";
      }
      console.log("run watcher", this.checkboxes.cgPrivacy.linkText);
    },
    allConsents: {
      handler() {
        if (this.allConsents.answer === "accepted") {
          this.checkboxes.cgPrivacy.state = true;
          this.checkboxes.cgPrivacy.answer = "accepted";
          if (this.workspacePrivacyPolicyLink.length > 0) {
            this.checkboxes.workspacePrivacy.state = true;
            this.checkboxes.workspacePrivacy.answer = "accepted";
          }
        } else {
          this.checkboxes.cgPrivacy.state = false;
          this.checkboxes.cgPrivacy.answer = "";
          this.checkboxes.workspacePrivacy.state = false;
          this.checkboxes.workspacePrivacy.answer = "";
        }
      },
      deep: true,
    },
  },
});
</script>
<template>
  <div>
    <BlockCheckbox
      v-bind="allConsents"
      v-bind.sync="allConsents"
      :disabled="allConsents.disabled"
      :linkTexts="[cgPrivacyPolicyLinkText, navigationWorkspaceName + ' Privacy Policy']"
      :linkTargets="[
        workspaceAllowCustomPolicy ? `${privacyPolicyLink}?queryWorkspaceId=${workspaceId}` : privacyPolicyLink,
        workspacePrivacyPolicyLink,
      ]"
      :padding="padding"
      :text="privacyPolicyText"
    />
    <template v-if="smsCheckBox">
      <BlockCheckbox v-bind="checkboxes.sms" v-bind.sync="checkboxes.sms" :padding="padding" />
    </template>
  </div>
</template>
