import { AddressInstance } from "./AddressInstance";
import { User, Verification, VerificationLog } from "@claimsgate/core-types";
import { saveVerificationLog } from "@claimsgate/core";
import { getFirebaseBackend } from "@/authUtils";

/**
 * Creates a verification log to be stored in the verification_logs collection, and stores the verification on the users veirfications array
 */
export async function createAndStoreVerification(state: AddressInstance, matchPassed: boolean, electoralResult: any) {
  const verification: Verification = {
    passed: matchPassed ?? false,
    funnelId: state.$route.params.funnelId,
    claimId: state.claimId ?? "",
    date: new Date(),
    types: ["Electoral Roll"],
    method: "T2A",
  };
  const user = state.userDataService.getCache() as User;
  // create the log to be saved to the verification_logs collection
  const verificationLog: VerificationLog = {
    ...verification,
    result: {
      addressLine1: user.addressLine1 ?? "",
      firstName: user.firstName ?? "",
      lastName: user.lastName ?? "",
      postcode: user.postcode ?? "",
      electoralRollMatchCodeSurname: electoralResult?.data["match_code_surname"] ?? "NO_MATCH",
      electoralRollMatchCodeForename: electoralResult?.data["match_code_forename"] ?? "NO_MATCH",
      electoralRollMatchCodeAddress: electoralResult?.data["match_code_address"] ?? "NO_MATCH",
      electoralRollValidationStatus: electoralResult?.data["validation_status"] ?? "NO_MATCH",
    },
  };

  // save the log, get the id
  const [verificationLogId, error] = await saveVerificationLog(
    getFirebaseBackend().firestore(),
    state.userService.getUserId(),
    verificationLog
  );
  if (error) {
    throw error;
  }

  verification.verificationLogId = verificationLogId;
  const userVerifications = state.userDataService.getArtefact("verifications") ?? [];
  userVerifications.push(verification);
  state.userDataService.setArtefact("verifications", userVerifications);
}
