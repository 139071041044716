import { validations } from "./validations";
import { computes } from "./computes";
import { BlockContactDetailsInstance } from "./blockContactDetails";
import { UserDataService } from "@/helpers/ClaimsGate/DataService";
import { getClaimDataService } from "@/helpers/vue";
import { userIsElectoralRollVerified, userIsIdVerified } from "@/helpers/ClaimsGate/verification";
export const methods = {
  validations,
  computes,
  emitSubmit,
  mounted,
};

async function emitSubmit(state: BlockContactDetailsInstance) {
  state.isProcessing = true;

  await state.$nextTick();

  await state.$store.dispatch("events/fire", { name: state.eventValues.next });

  state.isProcessing = false;
}
async function mounted(state: BlockContactDetailsInstance) {
  try {
    if (!state.userService.getUserId()) {
      return;
    }

    state.userDataService = new UserDataService(state.userService.getUserId());

    getClaimDataService<BlockContactDetailsInstance>(state);

    await state.userDataService.refresh();
    if (state.collectName) {
      const user = state.userDataService.getCache();
      state.userName = {
        firstName: user.firstName,
        lastName: user.lastName,
        title: user.title,
      };

      if (userIsIdVerified(user) || userIsElectoralRollVerified(user)) {
        state.userNameDisabled = true;
      }
    }
    state.isLoading = false;
  } catch (exception) {
    state.isLoading = false;

    state.infoModalService.fire("error");
  }
}
