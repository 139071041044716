import { navigationComputed } from "@/state/helpers";
import { BlockEmailInstance } from "./BlockEmail";
import { eventComputed } from "@/state/helpers";

export function mappedComputed() {
  return {
    ...(navigationComputed as Record<keyof typeof navigationComputed, any>),
    ...(eventComputed as Record<keyof typeof eventComputed, any>),
  };
}

export const computed = {
  blockState,
  blockDisabled,
  providedEmail,
  verifyEmailAttempted,
};

function providedEmail(state: BlockEmailInstance, answer?: string): string {
  if (answer) {
    state.BlockInputs.email.answer = answer;
  }
  return state.BlockInputs.email.answer;
}

function verifyEmailAttempted(state: BlockEmailInstance): boolean {
  return state.recover.verifyUserIdentifier.email;
}

/**
 * Combines the state boolean of email AND emailConfirm input block
 */
function blockState(state: BlockEmailInstance, blockState?: boolean): boolean {
  if (blockState !== undefined) {
    state.BlockInputs.email.state = blockState;
    state.BlockInputs.emailConfirm.state = blockState;
  }
  return state.BlockInputs.email.state && state.BlockInputs.emailConfirm.state;
}

/**
 * Combines the disabled boolean of email AND emailConfirm input block
 * @param state
 * @param blockDisabled
 * @returns
 */
function blockDisabled(state: BlockEmailInstance, blockDisabled?: boolean): boolean {
  if (blockDisabled !== undefined) {
    state.BlockInputs.email.disabled = blockDisabled;
    state.BlockInputs.emailConfirm.disabled = blockDisabled;
  }
  return state.BlockInputs.email.disabled && state.BlockInputs.emailConfirm.disabled;
}
