import { Props } from "@/types";
import { getInputDefaults } from "@/helpers/ClaimsGate/blocks/inputProps";
import { getProps as getConsentProps } from "../consent/props";
import { getProps as getEmailProps } from "../email/props";
import { getProps as getPhoneProps } from "../phone/props";

const blockContactDetailsProps: Props.BlockEmail &
  Props.BlockPhone &
  Props.BlockConsent &
  Props.BlockContactDetails & {
    returnEmcodeData: Props.CGPropExtras<boolean>;
    condition: any;
    allowMarketingOptIn: Props.CGPropExtras<boolean>;
    allowMarketingOptInText: Props.CGPropExtras<string>;
    collectName: Props.CGPropExtras<boolean>;
    displayCustomHeading: Props.CGPropExtras<boolean>;
    blockButton: Props.CGPropExtras<boolean>;
  } = {
  ...getPhoneProps(),
  ...getEmailProps(),
  ...getConsentProps(),
  ...(getInputDefaults(["padding"]) as { padding: Props.GenericInput["padding"] }),

  returnEmcodeData: {
    type: Boolean,
    default: () => false,
    required: false,
    allowVariable: false,
    description: "Set this to true if you want to return the emcode data",
  } as Props.CGPropExtras<boolean>,

  condition: {
    type: Object,
    required: false,
    default: () => {
      return {
        actual: {},
        values: [],
        value: "",
        type: "",
      };
    },
  },
  allowInternationalNumbers: {
    type: Boolean,
    default: false,
    required: false,
    allowVariable: false,
    description: "Set this to true if you want to allow international numbers",
  } as Props.CGPropExtras<boolean>,

  allowMarketingOptIn: {
    type: Boolean,
    default: false,
    required: false,
    allowVariable: false,
    description: "Set this to true if you want to allow marketing opt in",
  } as Props.CGPropExtras<boolean>,

  allowMarketingOptInText: {
    type: String,
    default: "I would like to receive marketing communications from Emarsys",
    required: false,
    allowVariable: true,
    description: "Set this to the text you want to display for marketing opt in",
  } as Props.CGPropExtras<string>,

  collectName: {
    type: Boolean,
    default: false,
    required: false,
    description: "Set this to true if you want to collect the users full name",
  } as Props.CGPropExtras<boolean>,

  displayCustomHeading: {
    type: Boolean,
    default: false,
    required: false,
    description: "Set this to true if you want to display the custom heading",
  } as Props.CGPropExtras<boolean>,
  blockButton: {
    type: Boolean,
    default: false,
    required: false,
    description: "Set this to true if you want to display the button as a block",
  } as Props.CGPropExtras<boolean>,
};

export function getProps() {
  return {
    ...blockContactDetailsProps,
  };
}
