import Vue from "vue";
import { methods } from "./methods";
import { getProps } from "./props";
import { getData } from "./data";
import { computed, mappedComputed } from "./computed";
export { methods, getProps, getData, computed, mappedComputed };
import { BlockInstanceProps, Props } from "@/types";

export type Methods = keyof typeof methods;
export type Data = ReturnType<typeof getData>;
export type Computed = { [K in keyof typeof computed]: ReturnType<typeof computed[K]> } & ReturnType<
  typeof mappedComputed
>;
export type BlockConsentInstance = Vue & Data & Methods & Computed & BlockInstanceProps<Props.BlockConsent>;
