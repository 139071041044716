import Vue from "vue";
import { methods } from "./methods";
import { getProps } from "./props";
import { getData } from "./data";
import { mappedComputed, computed } from "./computed";
import { AbstractUserBlockMethods } from "@/types/vue/AbstractUserBlockMethods";
export { methods, getProps, getData, mappedComputed, computed };
import { Props, BlockInstanceProps } from "@/types";
import { User } from "@claimsgate/core-types";

export type Methods = keyof typeof methods;
export type Data = ReturnType<typeof getData>;
export type Computed = { [K in keyof typeof computed]: ReturnType<(typeof computed)[K]> } & ReturnType<
  typeof mappedComputed
>;

export interface BlockContactDetailsRefs {
  $refs: {
    BlockEmail: AbstractUserBlockMethods;
    BlockPhone: AbstractUserBlockMethods;
    BlockConsent: AbstractUserBlockMethods;
    BlockSimpleName: {
      validations: () => Promise<boolean>;
      computes: () => Promise<Pick<User, "firstName" | "lastName" | "title">>;
    };
  };
}

interface BlockContactDetailsProps {
  returnEmcodeData: Props.Bool;
  allowMarketingOptIn: Props.Bool;
  allowMarketingOptInText: Props.Str;
}

type Props = BlockInstanceProps<ReturnType<typeof getProps>>;

/**
 * Passing Computed as a generic prevents TS from throwing circular reference error when
 * computed functions use a state object with `BlockEmailInstance` as its type
 */
export type BlockContactDetailsInstance = Vue & Data & Methods & Computed & Props & BlockContactDetailsRefs;
