import Vue from "vue";
import { methods } from "./methods";
import { getProps } from "./props";
import { getData } from "./data";
import { computed, mappedComputed } from "./computed";

export { methods, getProps, getData, computed, mappedComputed };

export type Methods = keyof typeof methods;
export type Data = ReturnType<typeof getData>;
export type Props = ReturnType<typeof getProps>;
export type Computed = { [K in keyof typeof computed]: ReturnType<(typeof computed)[K]> } & ReturnType<
  typeof mappedComputed
>;

export interface BlockAddressProps {
  collectName: boolean;
  allowOtherTitle: boolean;
  padding: string;
  collectVehicleFinanced: boolean;
  checkElectoralRoll: boolean;
  title: boolean;
  middleName: boolean;
  isChild: boolean;
  collectDateOfBirth: boolean;
  requireAgeCheck: boolean;
  minAge: string;
  maxAge: string;
  idFlowAllowsYoti: boolean;
  allowInternationalClients: boolean;
  preventUkPostcodeLookup: boolean;
  preventDefinedNameChange?: boolean;
  countryRestriction?: boolean;
}

/**
 * Passing Computed as a generic prevents TS from throwing circular reference error when
 * computed functions use a state object with `AddressInstance` as its type
 */
export type AddressInstance = Vue & Data & Methods & Computed & BlockAddressProps;
