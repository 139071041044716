<script lang="ts">
import Vue from "vue";
import {
  methods,
  getProps,
  getData,
  mappedComputed,
  BlockContactDetailsInstance,
  computed,
} from "./blockContactDetails";

import BlockEmail from "@/components/shared/blocks/user/email/email.vue";
import BlockPhone from "@/components/shared/blocks/user/phone/phone.vue";
import BlockConsent from "@/components/shared/blocks/user/consent/consent.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockButton from "@/components/shared/blocks/button.vue";
import BlockCheckbox from "@/components/shared/blocks/checkbox.vue";
import BlockSimpleName from "@/components/shared/blocks/simpleName.vue";
export default Vue.extend({
  name: "BlockContactDetails",
  components: {
    BlockPhone,
    BlockEmail,
    BlockConsent,
    BlockButton,
    BlockPadding,
    BlockCheckbox,
    BlockSimpleName,
  },
  props: { ...getProps() },
  computed: {
    ...mappedComputed(),
    loaderState(): boolean {
      //@ts-ignore
      return computed.loaderState(this as any as BlockContactDetailsInstance, this.loaders.next);
    },
  },
  data() {
    return { ...getData(this) };
  },

  methods: {
    async validations() {
      return methods.validations(this as any as BlockContactDetailsInstance);
    },
    async computes() {
      return methods.computes(this as any as BlockContactDetailsInstance);
    },
    emitSubmit() {
      return methods.emitSubmit(this as any as BlockContactDetailsInstance);
    },
  },
  async mounted() {
    await methods.mounted(this as any as BlockContactDetailsInstance);
  },
});
</script>
<template>
  <div v-if="isLoading === false">
    <template v-if="displayCustomHeading">
      <div class="tw-flex tw-flex-col tw-gap-3 tw-items-center tw-mb-2 tw-mt-8">
        <span class="tw-text-gray-900 tw-text-center tw-text-2xl tw-font-bold">What's your name?</span>
        <span class="tw-font-regular tw-text-gray-700 tw-text-center tw-text-md-regular"
          >Please make sure to include all the names that appear on your driver's licence/passport.</span
        >
      </div>
    </template>

    <template v-if="collectName">
      <BlockSimpleName
        ref="BlockSimpleName"
        v-bind="{ middleName: false, allowOtherTitle: true, answer: userName, disabled: userNameDisabled, padding }"
      ></BlockSimpleName>
      <!--<BlockPadding :padding="padding" />-->
    </template>
    <BlockEmail
      ref="BlockEmail"
      :enforceVerification="enforceVerification"
      :requireConfirm="requireConfirm"
      :padding="padding"
      :parentIsProcessing="isProcessing"
    />
    <BlockPhone
      ref="BlockPhone"
      :padding="padding"
      :parentIsProcessing="isProcessing"
      :allowInternationalNumbers="allowInternationalNumbers"
      :requireUkMobileNumber="requireUkMobileNumber"
    />

    <BlockConsent
      ref="BlockConsent"
      :workspaceTermsAndConditionsLink="workspaceTermsAndConditionsLink"
      :workspacePrivacyPolicyLink="workspacePrivacyPolicyLink"
      padding="35"
      :parentIsProcessing="isProcessing"
      :privacyPolicyText="privacyPolicyText"
      :smsCheckBox="smsCheckBox"
      :privacyPolicyLink="privacyPolicyLink"
    />

    <template v-if="allowMarketingOptIn">
      <BlockCheckbox
        ref="BlockCheckbox"
        :hideLink="true"
        :text="allowMarketingOptInText"
        padding="20"
        :linkText="''"
        :answer.sync="marketingOptIn"
      />
    </template>

    <BlockPadding :padding="padding" />

    <BlockButton
      :block="blockButton ? true : false"
      v-bind="submitButton"
      v-bind.sync="submitButton"
      :isProcessing="loaderState"
      @clicked="emitSubmit"
      :text="$t('blocks.button.continue')"
    />
  </div>
</template>
