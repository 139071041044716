import { onCallGateway } from "@/helpers/ClaimsGate/onCallGateway";

export async function getCountryByPostcodeFromPostcodeIo(postcode: string) {
  console.log(">>> Address getCountryByPostcodeFromPostcodeIo", postcode);
  const result = await onCallGateway({
    functionName: "getUkCountryByPostcode",
    data: { postcode },
  });

  const country = result?.data?.data?.country;

  console.log(">>> Address getCountryByPostcodeFromPostcodeIo", country);

  if (!country) {
    throw new Error("[callPostcodeIo] No country found for postcode: " + postcode);
  }

  return country;
}
