// Services
import { UserDataService } from "@/helpers/ClaimsGate/DataService";
import { getClaimDataService, getUserId } from "@/helpers/vue";
import { User } from "@claimsgate/core-types";

// Vue Instance
import { AddressInstance, methods } from "./AddressInstance";

import {
  userIsAddressVerified,
  userIsElectoralRollVerified,
  userIsIdVerified,
} from "@/helpers/ClaimsGate/verification";
import { isUkCountry } from "./methods";

export async function mounted(state: AddressInstance) {
  state.uiToggles.pageLoading = true;
  try {
    const userId = getUserId();

    if (userId) {
      state.userId = userId;
      if (!state.userDataService) {
        state.userDataService = new UserDataService(state.userService.getUserId());
      }
      await state.userDataService.refresh();
      const userData = state.userDataService.getCache() as User;
      state.userData = userData;

      getClaimDataService<AddressInstance>(state);

      loadUserDataIntoComponent(state, userData);

      // Need to show the address fields if already exist
      if (userData.addressLine1 && userData.city && userData.postcode) {
        state.uiToggles.addressSelected = true;
        state.BlockInputs.addressLine1.disabled = false;
        state.BlockInputs.city.disabled = false;
      }

      if (userData.unparsedDateOfBirth) {
        state.unparsedDateOfBirth = userData.unparsedDateOfBirth;
      }

      if (state.preventDefinedNameChange) {
        disableNameFieldsIfDefined(state);
      }

      state.uiToggles.pageLoading = false;
      if (state.preventUkPostcodeLookup) {
        state.uiToggles.restrictCountry = true;
      }
    } else {
      state.uiToggles.pageLoading = false;
    }
  } catch (e) {
    console.error(e);
    state.uiToggles.pageLoading = false;
    await state.infoModalService.fire("error", { text: state.uiMessages.pageLoadFailed });
  }
}

/**
 *  Disable fields based on existing verification status
 */
function disableFieldsByVerifications(userData: User, state: AddressInstance) {
  if (
    userIsElectoralRollVerified(userData) ||
    (userIsIdVerified(userData) && userIsAddressVerified(userData)) ||
    (state.user.country && state.user.country !== "United Kingdom" && userIsIdVerified(userData))
  ) {
    state.uiToggles.addressSelected = true;
    methods.disableCompletedFields(state);
    state.uiToggles.disabledOnMount = true;
    state.BlockInputs.postcodeSearchBlock.disabled = true;
  }

  // need to disable name fields so they can't be changed
  if (userIsIdVerified(userData)) disableNameFields(state);
}

function disableNameFields(state: AddressInstance) {
  state.BlockInputs.firstName.disabled = true;
  state.BlockInputs.middleName.disabled = true;
  state.BlockInputs.lastName.disabled = true;
  state.BlockInputs.title.disabled = true;
  state.BlockInputs.other.disabled = true;
}
/**
 * Loads the user data that is relevant to the component's input blocks into the data object
 */
function loadUserDataIntoComponent(state: AddressInstance, userData: User) {
  state.user = {
    ...state.user,
    title: userData.title ?? "",
    firstName: userData.firstName ?? "",
    middleName: userData.middleName ?? "",
    lastName: userData.lastName ?? "",
    addressLine1: userData.addressLine1 ?? "",
    city: userData.city ?? "",
    postcode: userData.postcode ?? "",
    country: userData.country ?? "",
  };

  if (state.user.country && isUkCountry(state.user.country)) {
    state.BlockInputs.livesInUkSelect.answer = "Yes";
  } else if (state.user.country) {
    state.BlockInputs.livesInUkSelect.answer = "No";
  }

  if (titleIsOther(state.user.title) && state.user.title) {
    state.BlockInputs.title.answer = "Other";
    state.BlockInputs.other.answer = state.user.title;
  } else {
    state.BlockInputs.title.answer = state.user.title as any;
  }
  if (state.collectDateOfBirth) {
    state.user.dateOfBirth = state.dateService.parseDate(userData.dateOfBirth ?? "");
    state.BlockInputs.dateOfBirth.answer = state.user.dateOfBirth;
  }
}

function titleIsOther(title: string): boolean {
  const titles = ["Mr", "Mrs", "Ms", "Miss", "Mx", "Dr", "Professor", "Prefer not to say", "Other"];
  return !titles.includes(title);
}

export function disableNameFieldsIfDefined(state: AddressInstance) {
  if (state.user.title) {
    state.BlockInputs.title.disabled = true;
  }
  if (state.user.firstName) {
    state.BlockInputs.firstName.disabled = true;
  }
  if (state.user.middleName) {
    state.BlockInputs.middleName.disabled = true;
  }
  if (state.user.lastName) {
    state.BlockInputs.lastName.disabled = true;
  }
}

export function disableAddressFieldsIfDefined(state: AddressInstance) {
  if (state.user.addressLine1) {
    state.BlockInputs.addressLine1.disabled = true;
  }
  if (state.user.city) {
    state.BlockInputs.city.disabled = true;
  }
  if (state.user.postcode) {
    state.BlockInputs.postcode.disabled = true;
    state.BlockInputs.postcodeSearchBlock.disabled = true;
  }
}
