import { ClaimDataService, UserDataService } from "@/helpers/ClaimsGate/DataService";
import { InfoModalService } from "@/components/shared/global/informationModal/InfoModalService";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";

export function getData(state: Vue) {
  return {
    submitButton: UserVariableBlocksService.genBlockButtonProps({ block: false }),

    isProcessing: false,
    userService: getUserHelper(),
    userDataService: undefined as UserDataService,
    claimDataService: undefined as ClaimDataService,
    userId: undefined as string,
    claimId: state.$route.params.claimId,
    pageId: state.$route.params.pageId,
    funnelId: state.$route.params.funnelId,
    isLoading: true,
    infoModalService: new InfoModalService(state.$infoModal),
    marketingOptIn: "",
    userName: {
      firstName: "",
      lastName: "",
      title: "",
    },
    userNameDisabled: false,
  };
}
